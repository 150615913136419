.about {
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    transform: translateY(50px);
}

.about.show {
    opacity: 1;
    transform: translateY(0px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;
}

.about h1 {
    text-align: center;
    margin-top: 0;
}

.about .about-wrapper {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    backdrop-filter: blur(3px);
    width: 50%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    overflow: hidden; 
}

.about .description, .about .skills {
    padding: 30px;
    margin-bottom: 0;
    text-align: center; 
}

.about ul {
    list-style-type: none;
    padding-left: 0;
    text-align: center;
    padding-bottom: 10px;
}

.about ul li {
    display: inline;
    margin: auto 10px;
}


@media screen and (max-width: 800px) {
    .about.show .about-wrapper {
        width: 80%;
    }
}

img{
    border-radius: 50%;
    width: 450px;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.5s);
    backdrop-filter: blur(3px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}   

@media screen and (max-width: 800px) {
    .about.show .about-wrapper {
        width: 80%;
    }
}

img{
    border-radius: 50%;
    width: 450px;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.5s);
    backdrop-filter: blur(3px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}   