@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

* {
  font-family: 'Roboto';
  font-weight: 400;
  box-sizing: border-box; 
}

html, body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

body::-webkit-scrollbar {
  display: none; 
}

#tsparticles {
  z-index: -1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

main {
  padding-top: 60px; 
  overflow-x: hidden; 
}

.container {
  max-width: 100%;
  overflow-x: hidden;
}
