.project-section {
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    transform: translateY(50px);
}

.project-section.show{
    opacity: 1;
    transform: translateY(0px);
}


.project-section h1 {
    text-align: center;
    color: azure;
}

.grid-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 10px;
}

.grid-container .grid-item{
    background-color: rgba(0, 0, 0, 0.57);
    border-radius: 10px;
    padding: 40px;
    text-align: center;
    backdrop-filter: blur(3px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}
.grid-item img{
    object-fit: cover;
    width: 200px;  
    max-height: auto;
    border-radius: 10px;
    margin-bottom: 10px; 
}

.grid-item h1{
    margin: auto;
    color: azure;
    margin-bottom: 10px;
}

.grid-item p {
    width: 500px; 
    color: azure;
    text-align: center; 
    margin: 0 auto; 
    box-sizing: border-box; 
    line-height: 1.5; 
}

@media screen and (max-width: 1200px) {
    .project-section.show .grid-container{
        grid-template-columns: repeat(1, 1fr);
    }
    .project-section.show .grid-item p {
        width: 100%;
    }   
}   

h4{
    margin: auto;
    color: lightcyan;
    margin-bottom: 10px;
}


@media screen and (max-width: 1200px) {
    .project-section.show .grid-container{
        grid-template-columns: repeat(1, 1fr);
    }
    .project-section.show .grid-item p {
        width: 100%;
    }   
}   

h4{
    margin: auto;
    color: lightcyan;
    margin-bottom: 10px;
}
