.date{
  color: #ffffff;
}

.icon-container {
position: relative;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
background: transparent;
border-radius: 50%;
overflow: hidden;
}

.icon-image {
width: 100%;
height: 100%;
object-fit: contain;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
border-radius: 50%;
background: transparent;
padding: 0;
margin: 0;
box-shadow: none;
}

.icon-overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: transparent;
cursor: pointer;
}
