.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000; /* Black background */
    color: #fff; /* White text color */
    z-index: 1000;
  }
  
  .infinity-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .infinity-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid rgba(88, 37, 182, 1); /* Purple color */
    border-radius: 50%;
    animation: infinity-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgba(88, 37, 182, 1) transparent transparent transparent; /* Purple color */
  }
  
  .infinity-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  
  .infinity-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  
  .infinity-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  
  @keyframes infinity-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loading-screen h3 {
    margin-top: 20px;
    font-size: 1.2rem;
  }