
  .navbar ul{
    height: 60px;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    margin: 10px auto;
    padding: none;
    width: 50%;
  }
  
  .navbar a {
    color: aliceblue;
    text-decoration: none;
    font-size: 20px;
    position: relative;
    top: 0;
    transition: all 0.2s ease-in-out;
  }

  .navbar li {
    list-style: none;
  }


  .navbar .initials {
    font-size: 30px;
    font-weight: bold;
  }

  .navbar a.active {
    top: -5px;
    color: #b1ddf1;
  }



  @media screen and (max-width: 800px) {
    .navbar ul {
      width: 50%;
    }
    .navbar ul li {
      margin: 0 10px;
    }
  }

  @media screen and (max-width: 460px) {
    .navbar ul {
      width: auto;
      height: 40px;
      justify-content: space-evenly;
      padding: 0px;
    }
    .navbar ul li a{
      font-size: 15px;
    }
  }
    