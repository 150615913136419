.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    flex: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    left: 0;
    right: 0;
}