body {
    color: white;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}

.education-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.education-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.rit-logo {
    width: 150px; /* Adjust the size as needed */
    height: 150px; /* Make it a square */
    margin-right: 20px;
    object-fit: cover; /* Ensure the image covers the square */
    border-radius: 0; /* Ensure the image is not rounded */
}

.education-content {
    text-align: left;
}

.courses-table {
    width: 100%;
    max-width: 800px; /* Adjust as needed */
    opacity: 0.9;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 2px solid white;
}

th, td {
    border: 1px solid white;
    padding: 8px;
}

th {
    background-color: #333;
    text-align: center;
}

td {
    background-color: #444;
}

td:first-child {
    text-align: center;
}

td:nth-child(2) {
    text-align: left;
}

.prose {
    font-size: 1rem;
}

.text-base-content {
    color: #ffffff;
}

.text-info {
    color: #17a2b8;
}

.text-blue {
    color: #82EEFD;
}

.text-green {
    color: #00ff40;
}
